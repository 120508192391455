import React from "react";
import {Route, Switch} from "react-router";
import Home from "./pages/Home";
import Consult from "./pages/Consult";
import ThankYou from "./components/ThankYou";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import Payment from "./pages/Payment";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import {getCurrentHost} from "./utils/current-host";
import Blog from "./pages/Blog";
import SingleBlog from "./pages/SingleBlog";
import { InsuranceDetail } from "./pages/InsuranceDetail";
import { Reschedule } from "./pages/Reschedule";

const currentHost = getCurrentHost();

const clientId =
  currentHost === "uat" || currentHost === "live"
    ? "AQpbt4Kvs_yfVpuj3taCh2niwMJjVzn2OU-RecRw7lK74GQSHq1gNa4vhYkAS7vKBX6DiQHQ6ZIup7G3"
    : "AReJ79TDcIHDDTPxjEv6JQJtZ5H4bZjkMtJi3cJKl_hXUa_pTHLWaK8CZ52V0jM7xV1Exof1x04Vntlu";

function App() {
  return (
    <div className="App">
      <PayPalScriptProvider
        options={{
          "client-id": clientId,
          "currency": "GBP",
          "disable-funding": "credit,card",
        }}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          {/* i don't know why this is even here */}
          {/* <Route exact path="/quality">
          <Quality />
        </Route> */}

          <Route exact path="/consult">
            <Consult />
          </Route>
          <Route exact path="/blogs">
            <Blog />
          </Route>
          <Route exact path="/thankyou">
            <ThankYou />
          </Route>
          <Route exact path="/reschedule/:slug">
            <Reschedule />
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Route exact path="/insurance-detail/:id">

         <InsuranceDetail/>
            
          </Route>
          <Route exact path="/payment/:id">
            <Payment />
          </Route>
          <Route exact path="/blogs/:slug">
            <SingleBlog />
          </Route>
          <Route path="*">
            <div>Page not found</div>
          </Route>
        </Switch>
      </PayPalScriptProvider>
    </div>
  );
}

export default App;

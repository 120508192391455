import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Form, Formik, useFormikContext } from "formik";
import { ReScheduleForm } from "./ReScheduleForm";
import * as Yup from "yup";
import { validateDDMMYYYY } from "../utils/date";
import { updateRescheduleDate, verifyReschedule } from "../api";
import { Redirect, useHistory } from "react-router-dom/cjs/react-router-dom.min";


var url = new URL(window.location.href);
var path = url.pathname;
var segments = path.split('/');
var value = segments[segments.length - 1];
const slug = value ;
console.log("slug",slug)



export const Reschedule =()=>{

const history = useHistory()
  // const formik = useFormikContext();
const [access,setAccess]= useState();

useEffect(()=>{
      verifyReschedule(slug).then((res)=>{
        setAccess(res.data)
      })  
    },[slug])


  if(access === false){  
    return <Redirect to="/thankyou" />;

  }else
  {  return(
        <div className="reschedule-section">
            <Navbar/>
            <Formik
          initialValues={{
            name: "",
            email: "",
            dob: "",
            contact: "",
            preferVideoConsultation: false,
            scheduleObject:null,
            availableDateTimeSchema:[],
            consultDate:"",
          }}  
          onSubmit={(values,{setSubmitting,resetForm})=>{
            console.log("value",values.scheduleObject.id)

            const doctorId = values.scheduleObject.id;
            
            updateRescheduleDate(slug,doctorId).then(res =>{
              if (res.status === 200) {
                history.push("/thankyou");
              } else {
              }
              setSubmitting(false);
            });
          }}
     
          >
            {()=>(
                <Form>
            <ReScheduleForm/>

                </Form>
            )}
              </Formik>
        </div>
    )}
    
}
import {Button, Grid, makeStyles, Box} from "@material-ui/core";
import React, {useState} from "react";
import {ReactComponent as CameraIcon} from "../assets/camera-small.svg";
import {ReactComponent as BrowseIcon} from "../assets/upload-small.svg";
import {ReactComponent as FileIcon} from "../assets/file-big.svg";
import {ReactComponent as CrossIcon} from "../assets/cross-small.svg";
import {useFormikContext} from "formik";
import {useEffect} from "react";
import Camera from "./Camera";
import {useTheme} from "@material-ui/styles";
import {useMediaQuery} from "@material-ui/core";
import {toast} from "react-toastify";
import {detectCameraPresence} from "../utils/camera";

const useStyles = makeStyles(theme => ({
  imageContainer: {
    position: "relative",
    height: "283px",
    width: "282px",
    border: "2px dashed #ccc",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "10px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      margin: "0 auto",
    },
  },
  backdrop: {
    "position": "absolute",
    "display": "flex",
    "top": 0,
    "left": 0,
    "right": 0,
    "bottom": 0,
    "borderRadius": "10px",
    "backgroundColor": "rgba(200,200,200,0.6)",
    "justifyContent": "center",
    "alignItems": "center",
    "flexDirection": "column",
    "zIndex": 10,
    "&>*": {
      margin: 10,
    },
  },
  buttonText: {
    marginLeft: 10,
  },
  imageHolder: {
    display: "block",
    backgroundColor: "white",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    zIndex: 20,
  },
  cross: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    top: -30,
    right: -50,
    borderRadius: "50%",
    backgroundColor: theme.palette.common.red,
    zIndex: 5,
  },
}));

const ImageBox = ({name}) => {
  const theme = useTheme();
  const matchesAboveSm = useMediaQuery(theme.breakpoints.up("sm"));
  const {setFieldValue} = useFormikContext();
  const [openCamera, setOpenCamera] = useState(false);

  useEffect(() => {
    if (openCamera) {
      detectCameraPresence().catch(err => {
        toast.error("Could not access camera");
        setOpenCamera(false);
      });
    }
  }, [openCamera]);

  const [file, setFile] = useState(null);

  const deleteFile = () => {
    setFile(null);
  };

  const closeCamera = () => {
    setOpenCamera(false);
  };

  useEffect(() => {
    setFieldValue(name, file);
    //eslint-disable-next-line
  }, [file, name]);

  const styles = useStyles();
  const [showBackdrop, setShowBackDrop] = useState(false);
  const mouseOverHandler = () => {
    if (file) return;
    setShowBackDrop(true);
  };
  const mouseLeaveHandler = () => {
    setShowBackDrop(false);
  };

  const randomId = Math.random().toString(36).substring(7);

  return (
    <Grid item xs={12} md={4}>
      <Camera onCapture={setFile} onClose={closeCamera} open={openCamera} />
      <div
        onMouseOver={mouseOverHandler}
        onMouseLeave={mouseLeaveHandler}
        className={styles.imageContainer}>
        {file && (
          <div
            className={styles.imageHolder}
            style={{
              backgroundImage: `url(${URL.createObjectURL(file)})`,
            }}>
            <figure onClick={deleteFile} className={styles.cross}>
              <CrossIcon />
            </figure>
          </div>
        )}
        <FileIcon />
        <Box color="#919191">
          <p>Choose a file or take a picture</p>
        </Box>
        {showBackdrop && (
          <div className={styles.backdrop}>
            <Button
              color="primary"
              htmlFor={randomId}
              onClick={() => setShowBackDrop(true)}
              component="label"
              variant="contained">
              <BrowseIcon />
              <span className={styles.buttonText}>Browse</span>
            </Button>

            <Button
              color="primary"
              variant="contained"
              component="label"
              onClick={() => {
                if (matchesAboveSm) {
                  setOpenCamera(true);
                }
              }}>
              <CameraIcon />
              <span className={styles.buttonText}>Camera</span>
              {!matchesAboveSm && (
                <input
                  type="file"
                  onChange={e => setFile(e.target.files[0])}
                  accept="image/*"
                  capture="user"
                  hidden
                />
              )}
            </Button>
          </div>
        )}
      </div>
      <input
        type="file"
        accept="image/*"
        containerElement="label"
        style={{display: "none"}}
        onChange={e => {
          setFile(e.target.files[0]);
        }}
        name={name}
        id={randomId}
      />
    </Grid>
  );
};

export default ImageBox;

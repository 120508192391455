import React, {useEffect, useState} from "react";
import {Button} from "@material-ui/core";

import {Grid, Box, makeStyles, Paper} from "@material-ui/core";
import "./Reschedule.scss";
import {useFormikContext} from "formik";
import DatePicker, {formatDate} from "../components/DatePicker";
import {getConsultationDate} from "../api";
import {toast} from "react-toastify";
import BookNow from "../components/BookNow";

const useStyles = makeStyles(theme => ({
  loading: {textAlign: "center"},
  checkbox: {marginRight: 10},
  checkLabel: {color: theme.palette.primary.main},
  second: {margin: "0 4.5%"},
  padding: {padding: "5% 0 0 10%"},
  label: {marginBottom: "10px"},

  form: {
    backgroundColor: "white",
    borderRadius: "5px",
  },
  align: {
    paddingLeft: "5%",
    paddingBottom: "5%",
  },
  gap: {
    marginTop: 20,
  },
  infoNote: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: "15px",
    color: "#721c24",
  },
  successInfo: {
    margin: 0,
    marginLeft: "5px",
  },
  paper: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "4%",
    },
  },
  alertSuccess: {
    color: "#0f5132",
    padding: "9px",
    backgroundColor: "#d1e7dd",
    borderRadius: " 8px",
    display: "flex",
  },
  disableDate: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    cursor: "not-allowed",
  },
}));

export const ReScheduleForm = () => {
  const classes = useStyles();
  const formik = useFormikContext();
  const [availableDateTimeSchema, setAvailableDateTimeSchema] = useState(null);
  const [availableTimes, setAvailableTimes] = useState(null);

  useEffect(() => {
    getConsultationDate()
      .then(arr => {
        setAvailableDateTimeSchema(arr);
        formik.setFieldValue("availableDateSchema", arr);
      })
      .catch(err => {
        toast.error(
          "An error occurred while trying to fetch the available dates"
        );
      });
  }, [formik.values.preferVideoConsultation]);

  useEffect(() => {
    if (formik.values.consultDate && availableDateTimeSchema) {
      const index = availableDateTimeSchema.findIndex(item => {
        return item.date === formik.values.consultDate;
      });
      if (index === -1) {
        console.log("did not find any");
        setAvailableTimes(null);
      } else {
        setAvailableTimes(availableDateTimeSchema[index]["time"]);
      }
      console.log("availableDateTimeSchema: " + availableDateTimeSchema);
    }
  }, [formik.values.consultDate, availableDateTimeSchema]);

  return (
    <div className="reschedule-form">
      <div className="container">
        <>
          {!availableDateTimeSchema ? (
            <Grid className={classes.second} container>
              <div className={classes.infoNote}>
                <i>
                  Prefer Video Consultation is currently not available. Please
                  try again some other time or directly contact to doctor
                  +44(0)20 300 54545
                </i>
              </div>
            </Grid>
          ) : (
            <>
              <div>
                <Grid item xs={12} md={6}>
                  <div
                    style={{
                      width: "75%",
                      paddingTop: "3%",
                      position: "relative",
                    }}>
                    <Box color="#919191">Pick a date</Box>
                    <div></div>

                    {
                      /*this disables the following datepicker since setting disabled attribute was not cutting it cause this is a custom Component*/
                      formik.values["paypal_token"] && (
                        <div className={classes.disableDate}></div>
                      )
                    }
                    <DatePicker
                      value={formik.values.consultDate}
                      disabled={formik.values["paypal_token"]}
                      onDateChange={({value}) => {
                        formik.setFieldValue("consultDate", formatDate(value));
                        formik.setFieldValue("scheduleObject", null);
                      }}
                      availableDates={availableDateTimeSchema?.map(
                        date => date.date
                      )}
                      placeholder="Select date for video consultation"
                    />
                  </div>
                </Grid>

                {!formik.values["scheduleObject"] && (
                  <>
                    <Grid item className={classes.paper} xs={12} md={6}>
                      <BookNow availableTimes={availableTimes} />
                    </Grid>
                  </>
                )}
                {formik.values["paypal_token"] && (
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: 20,
                        marginTop: 10,
                        marginLeft: -20,
                        width: "80%",
                      }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                        alignItems="center">
                        <Grid item>
                          Start time :
                          {formik.values["scheduleObject"]["start_time"]}
                        </Grid>
                        <Grid item>
                          End time:
                          {formik.values["scheduleObject"]["end_time"]}
                        </Grid>
                        <Grid item>
                          Price: {formik.values["paypal_amount"]}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "500",
                    marginTop: "10px",
                  }}>
                  Submit
                </Button>
              </div>
            </>
          )}
        </>

        
      </div>
    </div>
  );
};

import {Box, Button, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React, {useEffect} from "react";
import {useState} from "react";
import Dropzone from "react-dropzone";
import FileDisplay from "./FileDisplay";
import {Field, useFormikContext} from "formik";
import {ReactComponent as Cloud} from "../assets/cloud.svg";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: "5px",
  },
  mt: {
    marginTop: "0px",
  },
  drop: {
    textAlign: "center",
    border: "2px dashed #ccc",
    padding: "20px",
    borderRadius: "10px",
    marginBottom: "10px",
  },
  textarea: {
    width: "93%",
    resize: "none",
    border: `2px solid ${theme.palette.primary.main}`,
    minHeight: 100,
    padding: 10,
    borderRadius: 10,
  },
  files: {
    marginTop: 20,
  },
  cloudArrow: {
    position: "absolute",
    top: "596px",
    right: "29.2%",
  },
  fileInfo: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#17664e",
  },
  dNone: {
    display: "none",
  },
}));

const DescriptionAndFileSection = () => {
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState([]);

  const {setFieldValue} = useFormikContext();
  const formik = useFormikContext();

  useEffect(() => {
    setFieldValue("files", [...files, ...image]);
    //eslint-disable-next-line
  }, [files, image]);

  const deleteFile = name => {
    const clone = [...files];
    const index = clone.findIndex(item => item.name === name);
    if (index !== -1) {
      clone.splice(index, 1);
    }
    setFiles(clone);
  };

  const deleteImage = name => {
    const clone = [...image];
    const index = clone.findIndex(item => item.name === name);
    if (index !== -1) {
      clone.splice(index, 1);
    }
    setImage(clone);
  };
  const styles = useStyles();
  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item container direction="column">
        <Grid item xs>
          <Typography variant="h6" align="left" className={styles.title}>
            <Box color="#4F4F4F">Any other descriptions?</Box>
          </Typography>
        </Grid>
        <Grid item xs>
          <Field
            component="textarea"
            name="description"
            placeholder="Write description about your eyes"
            className={styles.textarea}
          />
        </Grid>
      </Grid>
      <Grid item container className={styles.mt} direction="column">
        <Grid item xs>
          <Typography variant="h6" align="left" className={styles.title}>
            <Box color="#4F4F4F">
              <p style={{marginBottom: 0}}>
                {" "}
                If you have the report from the Optician Upload Here.{" "}
              </p>
              <i className={styles.fileInfo}>
                (Multiple files can be uploaded)
              </i>
            </Box>
          </Typography>
        </Grid>
        <Grid item="center" xs>
          <Dropzone
            multiple
            onDrop={_files => {
              setFiles(p => [...p, ..._files]);
            }}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className={styles.drop}>
                <input {...getInputProps()} />
                <div>
                  <Cloud />
                </div>
                <p>
                  <Box color="#919191">
                    Drag and drop or browse to choose a file.
                  </Box>
                </p>
              </div>
            )}
          </Dropzone>
          <Button variant="contained" component="label" color="primary">
            <input
              type="file"
              multiple
              hidden
              onChange={e => {
                setFiles(p => [...p, ...e.target.files]);
              }}
            />
            UPLOAD
          </Button>

          <Grid
            container
            direction="column"
            spacing={2}
            className={styles.files}>
            {[...files].map(file => {
              return (
                <FileDisplay
                  deleteFile={() => deleteFile(file.name)}
                  file={file}
                  key={file.name}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid item container className={styles.mt} direction="column"> */}
      <Grid item container className={styles.dNone} direction="column">
        <Grid item xs>
          <Typography variant="h6" align="left" className={styles.title}>
            <Box color="#4F4F4F">
              <p style={{marginBottom: 0}}>Upload Your Eye Photos Here </p>
              <i className={styles.fileInfo}>
                (Multiple files can be uploaded)
              </i>
            </Box>
          </Typography>
        </Grid>
        <Grid item="center" xs>
          <Dropzone
            multiple
            onDrop={_image => {
              setImage(p => [...p, ..._image]);
            }}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className={styles.drop}>
                <input {...getInputProps()} />
                <div>
                  <Cloud />
                </div>
                <p>
                  <Box color="#919191">
                    Drag and drop or browse to choose a file.
                    <span className="file-info"></span>
                  </Box>
                </p>
              </div>
            )}
          </Dropzone>
          <Button variant="contained" component="label" color="primary">
            <input
              type="file"
              multiple
              hidden
              onChange={e => {
                setImage(p => [...p, ...e.target.files]);
              }}
            />
            UPLOAD
          </Button>

          <Grid
            container
            direction="column"
            spacing={2}
            className={styles.files}>
            {[...image].map(file => {
              return (
                <FileDisplay
                  deleteFile={() => deleteImage(file.name)}
                  file={file}
                  key={file.name}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DescriptionAndFileSection;

import {
  Checkbox,
  Grid,
  Box,
  makeStyles,
  Typography,
  Paper,
  Icon,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {ErrorMessage, Field, useFormikContext} from "formik";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {useEffect} from "react";
import "./Form.scss";
import InfoIcon from "@material-ui/icons/Info";

import {toast} from "react-toastify";
import {
  getClientSecretKey,
  getConsultationDate,
  getPricing,
  getReportPrice,
  insuranceCompanyDetails,
  postEmailData,
} from "../api";
import AdaptiveTextField from "./AdaptiveTextField";
import BookNow from "./BookNow";
import CheckoutForm from "./CheckoutForm";
import DatePicker, {formatDate} from "./DatePicker";
import PaypalPayment from "./PaypalPayment";
import ReCAPTCHA from "react-google-recaptcha";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";

const firstConfigs = [
  {
    name: "name",
    label: "Full Name",
    type: "text",
    placeholder: "Enter your Full Name",
  },
  {
    name: "email",
    label: "Email Address",
    type: "email",
    placeholder: "Enter your Email Address",
    pattern: "^[a-zA-Z]+@[a-zA-Z0-9]+.[a-zA-Z]+",
  },
  {
    name: "dob",
    label: "Date Of Birth",
    type: "date",
    placeholder: "DD/MM/YYYY",
  },
  {
    name: "contact",
    label: "Contact Number",
    type: "number",
    placeholder: "Enter your Phone Number",
  },
];

const insurerFields = [
  {
    name: "postal_code",
    label: "Postal Code",
    type: "text",
    placeholder: "Enter your Postal Code",
  },
  {
    name: "address",
    label: "Address",
    type: "text",
    placeholder: "Enter your  Address",
  },
  {
    name: "authorization_no",
    label: "Authorization Number",
    type: "text",
    placeholder: "Enter your authorization number",
  },
  {
    name: "membership_no",
    label: "Membership Number",
    type: "text",
    placeholder: "Enter your membership Number",
  },
];
export const STRIPE_KEY =
  "pk_live_51KJyziIdi6p5k9Ujkrb0IWGQVrYDeKlXH7KrJNpvejuYTW6xscDLWcbJ4yCTqeiPWgXMsDlCg6J61VASDfQ2eyFu00jQaRdARU";

const stripePromise = loadStripe(STRIPE_KEY);
const useStyles = makeStyles(theme => ({
  loading: {textAlign: "center"},
  checkbox: {marginRight: 10},
  checkLabel: {color: theme.palette.primary.main},
  second: {margin: "10px"},
  padding: {padding: "5% 0 0 10%"},
  label: {marginBottom: "10px"},
  insurerInfo: {
    marginBottom: "0",
    color: "#a70a0a",
    marginTop: "10px",
  },
  datetitle: {
    marginTop: "10px",
    marginBottom: 0,
  },
  align: {
    paddingLeft: "5%",
    paddingBottom: "5%",
  },
  gap: {
    marginTop: 20,
  },
  infoNote: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: "15px",
    color: "#721c24",
  },
  successInfo: {
    margin: 0,
    marginLeft: "5px",
  },
  paper: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "4%",
    },
  },

  alertSuccess: {
    color: "#0f5132",
    padding: "9px",
    backgroundColor: "#d1e7dd",
    borderRadius: " 8px",
    display: "flex",
  },
  disableDate: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    cursor: "not-allowed",
  },
}));

const Form = () => {
  const [amount, setAmount] = useState(null);
  const [pricingSchema, setPricingSchema] = useState(null);
  const [availableDateTimeSchema, setAvailableDateTimeSchema] = useState(null);
  const [availableTimes, setAvailableTimes] = useState(null);
  const classes = useStyles();
  const formik = useFormikContext();
  const [showSecondPart, setShowSecondPart] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const captchaRef = useRef(null);
  const [showInsurerField, setShowInsurerField] = useState(false);
  const [tokenValue, setTokenValue] = useState("");
  const [reportPrice, setReportPrice] = useState("");
  const [insurerDetail, setInsurerDetail] = useState("");
  const [paymentSucessStatus, setPaymentSuccessStatus] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [companyDetails, setCompanyDetails] = useState();

  const handleSelectChange = event => {
    setCompanyId(event.target.value);
    console.log(event.target.value);
    formik.setFieldValue("insurance_company_id", event.target.value);
  };

  useEffect(() => {
    insuranceCompanyDetails().then(res => {
      setCompanyDetails(res);
    });
  }, []);

  useEffect(() => {
    getReportPrice().then(res => {
      setReportPrice(res);
    });
  }, []);

  const getPaymentStatus = value => {
    setPaymentSuccessStatus(value);
    formik.setFieldValue("card_payment_status", value);
  };

  useEffect(() => {
    let isOkay = true;
    const values = formik.values;
    const names = ["name", "email", "dob", "contact"];
    names.forEach(name => {
      isOkay = isOkay && values[name];
    });
    setShowSecondPart(isOkay);
  }, [formik.values]);

  useEffect(() => {
    setShowInsurerField(formik.values.payment_method);
  }, [formik.values.payment_method]);

  useEffect(() => {
    getClientSecret();

    async function getClientSecret() {
      const value = formik.values.preferVideoConsultation
        ? amount
        : reportPrice;
      if (value) {
        var response = await getClientSecretKey(value);
        console.log(response);
        if (response.status === "success") {
          setClientSecret(response.clientSecret);
        }
      }
    }
  }, [amount, reportPrice]);

  // const options = ;
  // const shouldSecondPartBeShown = () => {
  //   let isOkay = true;
  //   const {values, errors, touched} = formik;
  //   const names = ["name", "email", "dob", "contact"];
  //   names.forEach(name => {
  //     isOkay = isOkay && values[name] && !errors[name];
  //   });
  //   return isOkay;
  // };

  const doctorId = formik.values?.["scheduleObject"]?.["doctor_id"];
  console.log("doctorId", doctorId);
  useEffect(() => {
    // setAmount(null);
    if (typeof doctorId === "undefined") return;
    if (typeof doctorId === "object") return;
    getPricing(doctorId)
      .then(arr => {
        setPricingSchema(arr);
      })
      .catch(err => {
        toast.error("An error occurred while trying to fetch the pricing data");
      });
  }, [doctorId]);

  useEffect(() => {
    if (formik.values.consultDate && availableDateTimeSchema) {
      const index = availableDateTimeSchema.findIndex(item => {
        return item.date === formik.values.consultDate;
      });
      if (index === -1) {
        console.log("did not find any");
        setAvailableTimes(null);
      } else {
        setAvailableTimes(availableDateTimeSchema[index]["time"]);
      }
    }
  }, [formik.values.consultDate, availableDateTimeSchema]);

  useEffect(() => {
    if (pricingSchema) {
      const isVideoChecked = formik.values.preferVideoConsultation;
      const price = isVideoChecked
        ? pricingSchema[0]["video"]
        : pricingSchema[0]["no_video"];
      setAmount(price);
    }
  }, [pricingSchema, formik.values.preferVideoConsultation]);

  const handleEmailChange = useMemo(() => {
    const data = formik.values.email;
    postEmailData(data).then(res => {
      setInsurerDetail(res.data);
    });
  }, [formik.values.email, postEmailData, setInsurerDetail]);

  useEffect(() => {
    insurerFields?.map(({name}) => {
      if (insurerDetail.length > 0) {
        if (insurerDetail[0]?.insurance_company_id) {
          formik.setFieldValue(
            "insurance_company_id",
            insurerDetail[0].insurance_company_id
          );
          setCompanyId(insurerDetail[0].insurance_company_id);
        }
        if (insurerDetail[0]?.[name]) {
          formik.setFieldValue(name, insurerDetail[0][name]);
        }
      } else {
        formik.setFieldValue(name, "");
        formik.setFieldValue("insurance_company_id", "");
        setCompanyId("");
      }
    });
  }, [insurerDetail]);
  useEffect(() => {
    getConsultationDate()
      .then(arr => {
        setAvailableDateTimeSchema(arr);
        formik.setFieldValue("availableDateSchema", arr);
      })
      .catch(err => {
        toast.error(
          "An error occurred while trying to fetch the available dates"
        );
      });
  }, [formik.values.preferVideoConsultation]);

  //local
  const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
  //live
  const SITE_KEY = "6LceAU0jAAAAAGHXKcU3a-r4WrVFS-OC8KGnQ1n7";

  const handleChange = value => {
    console.log("Captcha value:", value);

    setTokenValue(value);
    // if value is null recaptcha expired
  };

  console.log(formik.values.isInsured, "formikinsured");
  useEffect(() => {
    formik.setFieldValue("recaptcha", tokenValue);
  }, [tokenValue]);
  return (
    <div className="consult-form-section" style={{paddingBottom: 10}}>
      {formik.isSubmitting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "grid",
            placeItems: "center",
          }}>
          <CircularProgress color="primary" />
        </div>
      )}

      <div style={{backgroundColor: "white"}}>
        <Typography
          color="#4F4F4F"
          style={{paddingTop: "2%", paddingLeft: "3%"}}
          variant="h6"
          component="h6">
          Personal Information
        </Typography>
        <Grid className={classes.padding} container spacing={2}>
          {firstConfigs.map(({label, name, type, placeholder, pattern}) => {
            return (
              <Grid key={name} item xs={12} sm={6}>
                <label className={classes.label}>
                  <Box color="#919191">{label} </Box>
                </label>

                <div></div>
                <AdaptiveTextField
                  name={name}
                  type={type}
                  placeholder={placeholder}
                  pattern={pattern}
                />
              </Grid>
            );
          })}

          <Grid container alignItems="center">
            <Field name="preferVideoConsultation">
              {({field}) => {
                return (
                  <>
                    <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      {...field}
                      checked={formik.values.preferVideoConsultation}
                      disabled={
                        // !availableDateTimeSchema ||
                        formik.values["paypal_token"]
                      }
                    />
                    <span className={classes.checkLabel}>
                      Prefer Video Consultation
                    </span>
		    
                    <span className={classes.infoText}>
                        Note: Please email info@virtualeye.net if you do not see a suitable time slot. A time slot of your choice will be made available to you.
                    </span>
                  </>
                  
                );
              }}
            </Field>
          </Grid>

          <>
            {!availableDateTimeSchema &&
            formik.values["preferVideoConsultation"] ? (
              <Grid className={classes.second} container>
                <div className={classes.infoNote}>
                  <i>
                    Prefer Video Consultation is currently not available. Please email info@virtualeye.net if you do not see a suitable time slot. A time slot of your choice will be made available to you.
                  </i>
                </div>
              </Grid>
            ) : (
              <>
                <div className={classes.gap}></div>
                {formik.values["preferVideoConsultation"] && (
                  <Grid className={classes.second} container>
                    <Grid item xs={12} md={12}>
                      <div
                        style={{
                          width: "42%",
                          position: "relative",
                        }}>
                        <Box color="#919191">Pick a date</Box>
                        <div></div>
                        <div></div>

                        {
                          /*this disables the following datepicker since setting disabled attribute was not cutting it cause this is a custom Component*/
                          formik.values["paypal_token"] && (
                            <div className={classes.disableDate}></div>
                          )
                        }
                        <div className="date-select-block">
                          <DatePicker
                            value={formik.values.consultDate}
                            disabled={formik.values["paypal_token"]}
                            onDateChange={({value}) => {
                              formik.setFieldValue(
                                "consultDate",
                                formatDate(value)
                              );
                              formik.setFieldValue("scheduleObject", null);
                            }}
                            availableDates={availableDateTimeSchema?.map(
                              date => date.date
                            )}
                            placeholder="Select date for video consultation"
                          />
                        </div>
                      </div>
                    </Grid>

                    {!formik.values["scheduleObject"] && (
                      <>
                        {availableTimes && (
                          <p className={classes.datetitle}>Available Dates:</p>
                        )}

                        <Grid
                          item
                          container
                          className={classes.paper}
                          xs={12}
                          md={12}>
                          <BookNow availableTimes={availableTimes} />
                        </Grid>
                      </>
                    )}
                    {formik.values["paypal_token"] && (
                      <Grid item xs={12} md={6}>
                        <Paper
                          elevation={3}
                          style={{
                            padding: 20,
                            marginTop: 10,
                            marginLeft: -20,
                            width: "80%",
                          }}>
                          <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}
                            alignItems="center">
                            <Grid item>
                              Start time :{" "}
                              {formik.values["scheduleObject"]["start_time"]}
                            </Grid>
                            <Grid item>
                              End time:{" "}
                              {formik.values["scheduleObject"]["end_time"]}
                            </Grid>
                            <Grid item>
                              Price: {formik.values["paypal_amount"]}
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            )}
          </>

          <Grid container alignItems="center" item xs={12} md={12}>
            <p style={{margin: "0"}}>
              Choose Payment Method
              {/* {formik.values["scheduleObject"]["start_time"]}
        {formik.values["scheduleObject"]["start_time"]} */}
            </p>
          </Grid>
          <Grid container alignItems="center" item xs={6} md={2}>
            <Field name="payment_method">
              {({field}) => {
                return (
                  <>
                    {/* <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      // checked={showInsurerField}
                      name="isInsured"
                      // onChange={()=>{
                      //   setShowInsurerField(!showInsurerField)
                      // }}
                      checked={formik.values.isInsured}
                      onChange={formik.handleChange}
                    />

                    <span className={classes.checkLabel}>
                    Insured
                    </span> */}

                    <input
                      className={classes.checkbox}
                      {...field}
                      checked={field.value === "insured"}
                      onChange={formik.handleChange}
                      color="primary"
                      type="radio"
                      onClick={handleEmailChange}
                      id="insured"
                      name="payment_method"
                      value="insured"
                    />
                    <label className={classes.checkLabel} for="insured">
                      Insured{" "}
                    </label>
                  </>
                );
              }}
            </Field>
          </Grid>

          <Grid container alignItems="center" item xs={6} md={2}>
            <Field name="payment_method">
              {({field}) => {
                return (
                  <>
                    <input
                      className={classes.checkbox}
                      checked={field.value === "PayPal"}
                      onChange={formik.handleChange}
                      color="primary"
                      type="radio"
                      id="PayPal"
                      name="payment_method"
                      value="PayPal"
                    />
                    <label className={classes.checkLabel} for="PayPal">
                      PayPal
                    </label>
                  </>
                );
              }}
            </Field>
          </Grid>
          <Grid container alignItems="center" item xs={6} md={2}>
            <Field name="payment_method">
              {({field}) => {
                return (
                  <>
                    <input
                      className={classes.checkbox}
                      checked={field.value === "card"}
                      onChange={formik.handleChange}
                      color="primary"
                      type="radio"
                      id="card"
                      name="payment_method"
                      value="card"
                    />
                    <label className={classes.checkLabel} for="card">
                      Card
                    </label>
                  </>
                );
              }}
            </Field>
          </Grid>

          {showInsurerField === "insured" ? (
            <Grid style={{paddingBottom: "2%"}} container spacing={2}>
              <Grid xs={12} sm={12}>
                {insurerDetail.length > 0 ? (
                  <p className={classes.insurerInfo}>
                    *Please amend your insurance details if necessary.
                  </p>
                ) : (
                  <p className={classes.insurerInfo}>
                    *Please enter your FULL ADDRESS and INSURANCE DETAILS
                    below.​
                  </p>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <label className={classes.label}>
                  <Box color="#919191">Insurance Company</Box>
                </label>

                <Select2
                  value={companyId}
                  name="insurance_company_id"
                  className="form-control"
                  data={companyDetails}
                  onChange={handleSelectChange}
                  data-placeholder="Select your Insurance Company"
                />

                <div style={{color: "red"}}>
                  <ErrorMessage name="insurance_company_id" />
                </div>
              </Grid>
              {insurerFields.map(({label, name, type, placeholder}) => {
                return (
                  <Grid key={name} item xs={12} sm={6}>
                    <label className={classes.label}>
                      <Box color="#919191">{label}</Box>
                    </label>
                    <div></div>
                    <AdaptiveTextField
                      name={name}
                      type={type}
                      placeholder={placeholder}
                      value={insurerDetail && insurerDetail[0]?.[name]}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : showInsurerField === "PayPal" ? (
            <Grid item xs={10} md={12} style={{marginTop: 20}}>
              <p>Paypal</p>
              {!formik.values["paypal_token"] && (
                //  formik.values["scheduleObject"] &&
                //  pricingSchema &&
                //  amount &&
                <>
                  <PaypalPayment
                    amount={
                      formik.values.preferVideoConsultation
                        ? amount
                        : reportPrice
                    }
                    afterPaymentComplete={details => {
                      formik.setFieldValue("paypal_token", details.id);
                      formik.setFieldValue("payment_method", "PayPal");
                      formik.setFieldValue(
                        "paypal_amount",
                        details.purchase_units[0].amount.value
                      );
                    }}
                  />
                </>
              )}
            </Grid>
          ) : showInsurerField === "card" ? (
            <Grid item xs={12} md={12} style={{marginTop: 20}}>
              <Grid item xs={12} md={6} style={{marginTop: 20}}>
                <p>Card</p>

                {
                  !formik.values["paypal_token"] &&
                  //  formik.values["scheduleObject"] ? (
                  //    pricingSchema && amount &&
                  clientSecret ? (
                    <Elements
                      stripe={stripePromise}
                      options={{
                        // passing the client secret obtained from the server
                        clientSecret: clientSecret,
                      }}>
                      <CheckoutForm
                        clientSecret={clientSecret}
                        getPaymentStatus={getPaymentStatus}
                        amount={
                          formik.values.preferVideoConsultation
                            ? amount
                            : reportPrice
                        }
                        formik={formik}
                      />
                    </Elements>
                  ) : paymentSucessStatus ? (
                    <div className={classes.alertSuccess} role="alert">
                      <InfoIcon />
                      <p className={classes.successInfo}>
                        Payment Successful ..
                      </p>
                    </div>
                  ) : (
                    <p className={classes.loading}>Payment Method Loading ..</p>
                  )

                  //  ) : (
                  //    ""
                  //  )
                }
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          <Grid
            container
            alignItems="center"
            style={{marginTop: "10px", marginBottom: "10px"}}>
            <Field name="recaptcha">
              {({field}) => {
                return (
                  <>
                    <ReCAPTCHA
                      // sitekey={TEST_SITE_KEY}
                      sitekey={SITE_KEY}
                      ref={captchaRef}
                      size="normal"
                      onChange={handleChange}
                      // asyncScriptOnLoad={asyncScriptOnLoad}
                    />

                    {formik.touched.recaptcha && formik.errors.recaptcha && (
                      <p style={{color: "red"}}>{formik.errors.recaptcha}</p>
                    )}
                  </>
                );
              }}
            </Field>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Form;
